var getDefaultModel = function getDefaultModel(texts) {
  return {
    news: {
      featuredImage: true,
      publishedDate: true
    },
    carousal: {
      previousButtonIcon: '/content/files/content/newscarousel/previous-default.png',
      nextButtonIcon: '/content/files/content/newscarousel/next-default.png',
      defaultImage: '/content/files/content/newscarousel/img-news-placeholder.png'
    },
    layout: {
      extraLargeScreens: 6,
      largeScreens: 4,
      mediumScreens: 3,
      smallScreens: 2,
      extraSmallScreens: 1
    },
    accessibilitySettings: {
      contentElementAltText: texts === null || texts === void 0 ? void 0 : texts.ContentElementAltText
    }
  };
};

export { getDefaultModel };