import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from '../NewsCarousel.module.scss';
import { urlEscape } from '../../utils/helpers';
import classNames from 'classnames';

const LeftArrow = forwardRef(({ imagePath, onClick, disabled, onKeyDown, ariaLabel }, ref) => { 
  return (
    <button
      ref={ref}
      className={classNames('news-carousel-previous',styles.prev)} 
      onClick={!disabled ? onClick : undefined}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      tabIndex={0}
      onKeyDown={onKeyDown}
    > 
       <img
         src={urlEscape(imagePath)}
         className={classNames(styles.navigationIcon, disabled ? styles.btnDisabled  : '')}
         alt=""
         aria-disabled={disabled}
       />
    </button>
  );
});

LeftArrow.propTypes = {
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  imagePath: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled:PropTypes.bool,
};

export default LeftArrow;
