import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from '../NewsCarousel.module.scss';
import { urlEscape } from '../../utils/helpers';
import classNames from 'classnames';

const RightArrow = forwardRef(({ imagePath, onClick, disabled, apiLoading, onKeyDown, ariaLabel }, ref) => {
  return (
    <button
      ref={ref}
      className={classNames('news-carousel-next',styles.next)} 
      onClick={!disabled ? onClick : undefined}
      aria-label={ariaLabel}
      aria-disabled={disabled || apiLoading}
      tabIndex={0}
      onKeyDown={onKeyDown}
    > 
      {apiLoading === true ?  
        <div className={styles.loader} /> : (
      <img
        src={urlEscape(imagePath)}
        className={classNames(styles.navigationIcon, disabled ? styles.btnDisabled  : '')}
        alt=""
        aria-disabled={disabled}
      />
    )}
    </button>
  );
});

RightArrow.propTypes = {
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  imagePath: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled:PropTypes.bool,
  apiLoading:PropTypes.bool,
};

export default RightArrow;
