export const urlEscape = url => {
  url = unescape(url);
  const onlineImagePattern = /^((http|https):\/\/)/;
  return !onlineImagePattern.test(url) ? escape(url) : url;
};

function removedHtmlTag(text){
  //eslint-disable-next-line
  return  text && text.replace(/<[^>]*>?/gm, '').replace(/\&nbsp;/g, '');
}
export const setNewsHtml = model => {
  /* eslint-disable */
  let painText = removedHtmlTag(model.shortDescription);
  if(!painText) {
    painText = removedHtmlTag(model.mainDescription);
  }
  let getDes = painText;
  if (painText && painText.length > 250) {
    getDes = painText.substring(0, 250) + '...';
  }
  return getDes;
};

export const formatDate = date => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [ day, month, year ].join('.');
}

export const dateToString = date => {
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString([],options);
}