import { ofType } from 'redux-observable';
import { map, switchMap, pluck, groupBy, mergeMap } from 'rxjs/operators';
import { NEWS_REQUESTED, newsReceived } from './actions';
import { newsQuery } from './queries';

export default (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(NEWS_REQUESTED),
    groupBy(action => action.payload.contentBlockId),
    mergeMap(group =>
      group.pipe(
        switchMap(({ payload: { contentBlockId, index, size } }) => {
          const variables = {
            size,
            index,
          };
          return api.graphApi(newsQuery,variables).pipe(
            pluck('news'),
            map(news => newsReceived(contentBlockId, news)),
          );
        }),
      ),
    ),
  );
};
