export const NEWS_REQUESTED = 'NEWS_CAROUSEL/NEWS_REQUESTED';
export const requestNews = (
  contentBlockId, 
  index,
  size,
) => ({
  type: NEWS_REQUESTED,
  payload: { contentBlockId, index, size },
});

export const NEWS_RECEIVED = 'NEWS_CAROUSEL/NEWS_RECEIVED';
export const newsReceived = (contentBlockId, news) => ({
  type: NEWS_RECEIVED,
  payload: { contentBlockId, news },
});